<template>
  <div>
    <v-card v-for="(section, sectionIndex) in sections" :key="'section-' + sectionIndex" ref="sectionRef">
      <v-card-title v-if="section.showTitle && section.title">{{ section.title }}</v-card-title>
      <v-card-text>
        <form-section :inputs="section.inputs" :form-data="formData" @save="$emit('save')" @update="(obj) => $emit('update', obj)" ref="formSectionRef"></form-section>
      </v-card-text>
      <v-card-actions v-if="sectionIndex === sections.length - 1">
        <v-btn v-if="returnButton" :disabled="isUploading" class="no-print" text @click="$emit('return')">{{ returnButton }}</v-btn>
        <v-btn :disabled="isUploading" color="primary" class="no-print" text @click="submit">{{ isUploading ? 'Waiting on files to upload' : submitLabel }}</v-btn>
        <slot name="afterButtons"></slot>
      </v-card-actions>
    </v-card>
    <payment-popup ref="paymentPopup" :total="paymentTotal" :items="paymentItems" :show-activator="false" :department="paymentDepartment" :foapal="paymentFoapal" @success="paymentSuccessful"></payment-popup>
  </div>
</template>
<script>
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    FormSection: () => import('./Section'),
    PaymentPopup: () => import('../../core/PaymentPopup')
  },
  props: {
    sections: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    returnButton: String,
    secondaryButton: String,
    submitLabel: {
      type: String,
      default: 'Submit'
    }
  },
  setup (props, { emit, root }) {
    const sectionRef = ref(null)
    const formSectionRef = ref([])
    const paymentPopup = ref(null)
    const paymentDepartment = computed(() => root.$store.state.forms.payment.department)
    const paymentFoapal = computed(() => root.$store.state.forms.payment.foapal)
    const paymentTotal = computed(() => root.$store.state.forms.payment.total)
    const paymentItems = computed(() => root.$store.state.forms.payment.items)

    const isUploading = computed(() => root.$store.state.forms.isUploading)

    function submit () {
      let valid = true
      formSectionRef.value.forEach(async (section) => {
        if (!section.validate()) {
          valid = false
        }
      })
      if (valid) {
        if (paymentTotal.value > 0) {
          paymentPopup.value.activate()
        } else {
          emit('submit')
        }
      }
    }

    function paymentSuccessful (payment) {
      emit('submit', { payment })
    }

    return {
      sectionRef,
      formSectionRef,
      paymentPopup,
      paymentDepartment,
      paymentFoapal,
      paymentTotal,
      paymentItems,
      isUploading,
      submit,
      paymentSuccessful
    }
  }
}
</script>
